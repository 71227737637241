<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12">
        <card-school :school="school" :edit="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <form-school action="edit" :school-prop="school" v-if="fetch" :edit="true"></form-school>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    school: {},
    fetch: false
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async getSchoolData (organizationId) {
      try {
        this.setLoading(true)
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.fetch = true
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {
        this.setLoading(false)
      }
    },
    handleClick (value) {
      this.$router.push({ path: '/class' })
    }
  },
  mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (id) {
      this.getSchoolData(id)
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
  }
}
</script>
